<template>
    <div>
        <v-dialog v-model="dialog" max-width="1024px">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    {{ title }}
                </v-card-title>
                <v-card-text style="text-align: center">
                    <v-row style="margin-top: 1rem">
                        <template v-if="multiple">
                            <v-col cols="12">
                                <h3 class="text-left">
                                    <strong>NOTA: </strong>
                                    El correo se enviará con el mismo asunto y
                                    cuerpo para todos los gestores, con las
                                    facturas adjuntas corresponidnetes a sus
                                    clientes asociados.
                                </h3>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="asunto"
                                    label="Asunto"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </template>
                        <v-col cols="12">
                            <RichTextComponent
                                v-model="descripcion"
                            ></RichTextComponent>
                        </v-col>
                        <v-col cols="12" v-if="!multiple">
                            <v-file-input
                                v-model="files"
                                dense
                                counter
                                multiple
                                show-size
                                small-chips
                                truncate-length="15"
                                outlined
                                label="Seleccione archivos adjuntos al email"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn color="error" large @click="dialog = false"
                        >Cancelar</v-btn
                    >
                    <v-btn color="success" large @click="confirm()"
                        >Confirmar</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isErrorMessageDialog" max-width="500">
            <v-card>
                <v-card-title
                    class="text-h5 aviso"
                    style="
                        justify-content: center;
                        background: #1d2735;
                        color: white;
                    "
                >
                    Aviso
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <h4>
                            No ha sido posible enviar correo a los siguientes
                            clientes
                        </h4>
                        <v-col cols="12">
                            <v-data-table
                                :items="fallidos"
                                :headers="headers"
                            ></v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="error" @click="isErrorMessageDialog = false"
                        >Cerrar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import RichTextComponent from "../../tickets/componentes/RichTextComponent.vue";
export default {
    components: {
        RichTextComponent,
    },
    props: [
        // General
        "value",
        "title",

        // Single
        "id_factura",
        "kit",

        // Multiple
        "multiple",
        "tipo",
        "facturas",
    ],
    data() {
        return {
            asunto: "",
            descripcion: null,
            files: [],
            dialog: false,
            isErrorMessageDialog: false,
            fallidos: [],
            headers: [
                { text: "Cliente", value: "nombre", sortable: false },
                { text: "Email", value: "email", sortable: false },
            ],
        };
    },
    created() {
        this.dialog = this.value;
        this.SetAsunto();
        this.SetDescripcion();
    },
    watch: {
        value(val) {
            this.dialog = val;
            this.SetDescripcion();
        },
        dialog(val) {
            this.$emit("input", val);
            this.SetDescripcion();
        },
    },
    computed: {
        isMorning() {
            var currentTime = new Date();
            var currentHour = currentTime.getHours();
            // Define morning hours (for example, 6 AM to 11:59 AM)
            var morningStartHour = 6;
            var morningEndHour = 11;

            // Check if current hour is in the morning
            if (
                currentHour >= morningStartHour &&
                currentHour <= morningEndHour
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        SetAsunto() {
            if (this.multiple) {
                this.asunto =
                    this.tipo == 1
                        ? "Facturas adjuntas"
                        : "Facturas Proforma adjuntas";
            }
        },
        SetDescripcion() {
            // console.log("prueba");
            this.descripcion = `<p>${
                this.kit
                    ? ` Mandamos adjunta factura correspondiente al Kit,para presentar su justificación. . Necesitamos nos envíes el
justificante de abono, recuerda que solo tienes que abonar la parte correspondiente al IVA, y anota en el concepto el número de factura.`
                    : `Mandamos adjunta factura. Necesitamos nos envíes el justificante de abono, con el número de factura en el concepto.`
            }
</p>
<strong>Gracias por la confianza.<br />
Un saludo</strong>`;
        },
        async confirm() {
            if (!this.multiple) {
                await this.sendSingleEmail();
            } else {
                await this.sendMultipleEmail();
            }

            this.dialog = false;
        },
        async sendSingleEmail() {
            try {
                const formData = new FormData();
                formData.append("descripcion", this.descripcion);
                // Añade cada archivo al FormData
                this.files.forEach((file, index) => {
                    formData.append(`files[]`, file); // Usa `files[]` para indicar un array de archivos
                });

                const res = await axios.post(
                    `api/facturas/mail/${this.id_factura}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                this.$toast.sucs(res.data.success);
            } catch (ex) {
                // console.log(ex.response);
                this.$toast.error(ex.response.data.error);
            }
        },
        async sendMultipleEmail() {
            try {
                const res = await axios.post(`api/facturas/mail`, {
                    facturas: this.facturas,
                    tipo: this.tipo,
                    asunto: this.asunto,
                    cuerpo: this.descripcion,
                });
                console.log("response", res.data.fallidos.length);

                if (res.data.fallidos.length == 0) {
                    this.$toast.sucs(res.data.success);
                } else {
                    this.fallidos = res.data.fallidos;
                    this.isErrorMessageDialog = true;
                }
            } catch (error) {
                this.$toast.error(err.response.data.error);
            }
        },
    },
};
</script>
